app-root {
  height: 100%;
}

/** Custom spinner wrapper **/
.custom-spinner-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(247, 248, 250, 0.4);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
}

/** Custom class for hiding input type file **/
.hide-input-type-file {
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  display: none;
  width: 0;
  height: 0;
}

/** Custom opacity 1 to mat-tab-group **/
.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-focus-indicator.mat-tab-label-active {
  opacity: 1 !important;
}

/** Custom 2 rows elipsis **/
.text-ellipsis-2-rows {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 40px;
}

/** Custom 1 row elipsis **/
.text-ellipsis-1-row {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 30px;
}